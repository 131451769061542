import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { EntidadService } from 'src/app/services/entidad.service';
import { Router,NavigationExtras } from '@angular/router';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare function init_location();
declare function selectsForms();
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {

  registroForm: FormGroup;
  formRegistro = true;
  submitted = false;
  errors: string = null;
  llegada: any;

  documentos: any[] = [
    { iddocumento: 1, abreviatura: 'DNI' },
    { iddocumento: 3, abreviatura: 'CARNET EXT.' },
    { iddocumento: 4, abreviatura: 'PASAPORTE y OTROS' }
  ];

  constructor(
    private entidadService: EntidadService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    init_location();
  }

  ngOnInit() {
   
    if (this.entidadService.usuario) {
      this.router.navigate(['/appointment']);
    }else{
      this.registroForm = this.createForm();
      this.registroForm.get('iddocumento').valueChanges.subscribe((data) => {
        if (data === 1) { // DNI
          this.registroForm.get('numerodoc').setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8)]);
        } else {
          this.registroForm.get('numerodoc').setValidators([Validators.required]);
        }
        this.registroForm.get('numerodoc').updateValueAndValidity();
      });
      setTimeout(() => {
        selectsForms();
        this.spinner.hide();
      }, 1000);
    }
    
  }

  createForm(): FormGroup {
    return new FormGroup({
      iddocumento: new FormControl(1, Validators.required),
      numerodoc: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      passwordrepeat: new FormControl(null, [Validators.required, confirmPasswordValidator]),
    });
  }

  get f() { return this.registroForm.controls; }

  authenticar(): void {

    const param = {
      iddocumento: this.registroForm.get('iddocumento').value,
      username: this.registroForm.get('numerodoc').value,
      password: this.registroForm.get('password').value,
      navegador: navigator.userAgent
    };

    this.entidadService.authenticate(param).subscribe((data) => {
      this.submitted = false;
      console.log('subscribe', data);
      if (data.type === 'success') {
        sessionStorage.setItem("data",JSON.stringify(data.data));
        this.router.navigate(['/appointment']);
      } else {
        alert(data.data);
      }
    }, error => {
      this.submitted = false;
      alert('Error');
    });
  }

  save(): void {

    this.submitted = true;
    
    if (this.registroForm.invalid) {
      return;
    }
    const valores = JSON.parse(sessionStorage.getItem('Invitado'));
    let param: any;
    param = this.registroForm.getRawValue();
    param.apellidopat = valores.apellidopat;
    param.celular = valores.celular;
    param.nombre = valores.nombres;

    this.spinner.show();
    this.entidadService.createPaciente({ entidad: param }).subscribe((data) => {
      this.submitted = false;
      this.spinner.hide();
      if (data.type === 'success') {
        this.authenticar();
      } else {
        this.errors = data.data.message;
        setTimeout(() => {
          this.errors = null;
        }, 5000);
      }
    }, error => {
      this.submitted = false;
      this.errors = 'Error';
      this.spinner.hide();
      setTimeout(() => {
        this.errors = null;
      }, 5000);
    });

  }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordrepeat');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
}
