import { Component, OnInit } from '@angular/core';
import { timer, Subscription } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import { Router } from '@angular/router';
import { CitamedicaService } from 'src/app/services/citamedica.service';


declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  estado_session_pago = FormatTimePipe.estado_session_pago;
  countDown: Subscription;
  errors: string = null;
  counter = 300;
  tick = 1000;
  public static time_status_: boolean = false;
  time_status = false;
  constructor(
    private citamedicaService: CitamedicaService,
    private router: Router

  ) { }

  closeModalCita() {
    $("#modal_cita").removeClass('is-visible')
    $("#overlay").removeClass('is-visible')

    $("#modal_cita_costo_cero").removeClass('is-visible')
    $("#overlay").removeClass('is-visible')
    this.router.navigate(['/']);
  }

  ngOnInit() {
    if (this.estado_session_pago == true) {
      this.EliminarCitaTemporal();
      setTimeout(() => {
        this.estado_session_pago = false;
        FormatTimePipe.estado_session_pago = false;
      }, 5000);
    }

    const datosPaciente = JSON.parse(sessionStorage.getItem('data'));
    if (datosPaciente && HeaderComponent.time_status_ != true) {
      this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
      HeaderComponent.time_status_ = true;
      this.time_status = true;
    }
  }

  ngOnDestroy() {
    this.countDown = null;
    this.estado_session_pago = false;
  }

  EliminarCitaTemporal() {
    const datosPaciente = JSON.parse(sessionStorage.getItem('data'));
    if (datosPaciente) {
      this.citamedicaService.destroyCitasTemporales({ 'id': datosPaciente.entidad.identidad })
        .subscribe((data: any) => {

          sessionStorage.clear();
          localStorage.clear();
          HeaderComponent.time_status_ = false;
          this.time_status = false;
        });
    }
  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {

  public static estado_session_pago: boolean = false;

  errors: string = null;

  constructor(
    private router: Router

  ) { }
  transform(value: number): string {
    if (value < 0) {
      FormatTimePipe.estado_session_pago = true;
      this.router.navigate(['/']);
    }
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}