import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import { Utils } from './utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UbigeoService extends ServiceBase {

  constructor(
    public http: HttpClient,
    private utils: Utils
  ) {
    super();
  }


  GetIndex(request) {
    return this.getQuery('osi/ubigeo',   request).pipe(
      map(data => {
        return data;
      })
    );
  }

}
