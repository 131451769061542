import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceSitedsBase } from './serviceSitedsBase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OsitedsService extends ServiceSitedsBase {

  constructor(public http: HttpClient) {
    super();
  }

  // Metodos
  GetSedesDeIpress(): Observable<any> {
    return this.getQuery('api/siteds-obtener-sedes-ipress-osi').pipe(
      map(data => {
        return data;
      }));
  }

  GetIafasOsiteds(): Observable<any> {
    return this.getQuery('api/siteds-obtener-iafas-asociadas-appsiteds').pipe(
      map(data => {
        return data;
      }));
  }

  GetEntidadVinculada(params?: any): Observable<any> {
    return this.getQuery('api/siteds-getconsulta-entvinculada', params).pipe(
      map(data => {
        return data;
      }));
  }

  GetCodigoAfiliadoSiteds(params?: any): Observable<any> {
    return this.getQuery('api/siteds-getconsulta-asegnom', params).pipe(
      map(data => {
        return data;
      }));
  }

  GetDetalleAseguradoSiteds(params?: any): Observable<any> {
    return this.getQuery('api/siteds-getconsulta-asegcod', params).pipe(
      map(data => {
        return data;
      }));
  }

  GetCondicionesMedicas(params?: any): Observable<any> {
    return this.getQuery('api/siteds-consultar-condiciones-medicas', params).pipe(
      map(data => {
        return data;
      }));
  }


}
