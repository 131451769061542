import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from './serviceBase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CitamedicaService extends ServiceBase {

  constructor(public http: HttpClient) {
    super();
  }

  // Metodos
  turnos(params?: any): Observable<any> {
    return this.getQuery('osi/citamedicarc/medico/disponibilidad', params).pipe(
      map(data => {
        return data.data;
      }));
  }

  CreatePagar(objeto) {
    return this.postQuery('osi/citamedica/pagarIzipay', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }

  temporalPacientesParameters(objeto) {
    return this.postQuery('osi/citamedica/temporalPacientesParameters', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }

  ValidarCitamedicaPortal(objeto) {
    return this.postQuery('osi/citamedica/ValidarCitamedicaPortal', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }
  CreatePagarPe(objeto) {
    return this.postQuery('osi/citamedica/pagarPagoefectivo', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }

  CreateCitamedicaCero(objeto) {
    return this.postQuery('osi/citamedica/reservaCitamedicaCero', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }

  CreateTempCitaMedica(objeto) {
    return this.postQuery('osi/citamedicarc/tempCitamedica', objeto).pipe(
      map(data => {
        return data;
      })
    );
  }
  
  IpreesSited(params?: any): Observable<any> {
    return this.getQuery('osi/citamedica/IpreesSitedListar').pipe(
      map(data => {
        return data;
      }));
  }

  disponibilidad(params): Observable<any> {
    return this.getQuery('osi/sede/horarios/disponibilidad',params).pipe(
      map(data => {
        return data;
      }));
  }

  destroyCitasTemporales(params): Observable<any> {
    return this.postQuery('osi/citamedica/deleteCitasTemporales',params).pipe(
      map(data => {
        return data;
      }));
  }
  
//   function IpreesSited() {
//     return $http.get($rootScope.servidor + '/citamedica/IpreesSitedListar').then(handleSuccess, handleError('Recurso no encontrado'), verificarToken());
// }

  
}
